import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store";
import ClientAPI from "./ClientAPI";

export default class PhysService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async postCount(payload: any) {
    return ClientAPI.Post(`phys/count`, payload);
  }

  async postRecount(payload: any) {
    return ClientAPI.Post(`phys/recount`, payload);
  }

  async checkPart(part: any, location: any, bin_no: any, recount: any) {
    const params = {
      part,
      location,
      bin_no,
      recount,
    };
    return ClientAPI.Get(`phys/checkPart`, params);
  }
}
